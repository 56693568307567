import React from 'react';
// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import posed from 'react-pose';
import ReactSVG from 'react-svg';
import FooterStyles from './footer_styles';
import theme from '../../../common/theme';
import Button from '../../../common/components/button';

const CtaPose = posed.div({
  visible: { staggerChildren: 100 },
  hidden: {},
});

const CtaLink = posed.div({
  visible: { y: '0%', opacity: 1, transition: { duration: 100, type: 'spring' } },
  hidden: { y: '-100%', opacity: 0 },
});

class CtaButtons extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showJoinLinks: false };
  }

  render() {
    const { showJoinLinks } = this.state;

    const toggleJoinLinks = (e) => {
      e.preventDefault();
      this.setState({ showJoinLinks: !showJoinLinks });
    };

    return (
      <div className="cta-buttons">
        <div className="social-container">
          <a href="https://www.facebook.com/VinettaProject/" target="_blank" rel="noopener noreferrer">
            <ReactSVG src="/vector/facebook-square.svg" />
          </a>
          <a href="https://twitter.com/vinettaproject" target="_blank" rel="noopener noreferrer">
            <ReactSVG src="/vector/twitter-square.svg" />
          </a>
          <a href="https://www.linkedin.com/company/the-vinetta-project" target="_blank" rel="noopener noreferrer">
            <ReactSVG src="/vector/linkedin-square.svg" />
          </a>
        </div>
        <ul className="buttons">
          <li>
            <Button borderless background="#FFFFFF" color={theme.color.primary} onClick={toggleJoinLinks}>
              Join a Chapter
            </Button>
          </li>
          <li>
            <Button
              borderless
              background={theme.color.primary}
              color="#fff"
              link="mailto:info@vinettaproject.com?subject=I%20want%20to%20launch%20a%20Chapter!"
            >
              Start a Chapter
            </Button>
          </li>
          <li>
            <Button borderless background="#FFFFFF" color={theme.color.primary} link="http://eepurl.com/bMOHm1">
	      Subscribe
            </Button>
          </li>
        </ul>
        {showJoinLinks && (
          <CtaPose className="join-links" initialPose="hidden" pose="visible">
            <Link to="/founder">
              <CtaLink>Founder</CtaLink>
            </Link>
            <Link to="/investor">
              <CtaLink>Investor</CtaLink>
            </Link>
            <Link to="/corporate-partner">
              <CtaLink>Corporate Partner</CtaLink>
            </Link>
          </CtaPose>
        )}
      </div>
    );
  }
}

const FooterView = () => (
  <FooterStyles>
    <div className="container">
      <Link className="logo" to="/">
        <img src="/logo-mark.svg" alt="logo" />
        <img src="/logo-wordmark.svg" alt="logo" />
      </Link>
      <ul className="links">
        <li>
          <Link to="/about">ABOUT</Link>
        </li>
        <li>
          <Link to="/chapters">CHAPTERS</Link>
        </li>
        <li>
          <Link to="/programs">PROGRAMS</Link>
        </li>
        <li>
          <Link to="/services">SERVICES</Link>
        </li>
        <li>
          <Link to="/team">TEAM</Link>
        </li>
      </ul>
      <CtaButtons />
    </div>
  </FooterStyles>
);

export default FooterView;
